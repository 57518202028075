body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Custom CSS */

table.stickycolumn {
  margin-left: -2px;
}
table.stickycolumn td:first-child {
  position: sticky;
  left: 0px;
  background: white;
  z-index: 10;
}

table.stickyheader {
  margin-top: -2px;
  border-spacing: 0px;
}
table.stickyheader th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 11;
}

@media print {
  .printPagebreak { page-break-after: always; } /* page-break-after works, as well */
}

@media screen {
  .printFooter {
    display: none;
  }
}
@media print {
  .printFooter {
    position: fixed;
    bottom: 0;
  }
}
